import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../../../sections/Navbar/Navbar'
import Footer from '../../../sections/Footer'
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import "../../../styles/AdminPage.scss";

function TeamsApp({ data }: any) {
  const info = data.prismicHowTheTeamsAppWorks.dataRaw
  const infoImg = data.prismicHowTheTeamsAppWorks.data
  return (
    <div>
      <Navbar />
      <div className='AdminPage'>
        <div className='AdminPage-title'>
          <PrismicRichText field={info.title} />
        </div>
        <div className='AdminPage-content'>
          <PrismicRichText field={info.text} />
        </div>
        <div className='AdminPage-second-title'>
          <PrismicRichText field={info.title_2} />
        </div>
        <div className='AdminPage-content'>
          <PrismicRichText field={info.texte_2} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TeamsApp;

export const query = graphql`
query TeamsApp {
  prismicHowTheTeamsAppWorks(lang: {eq: "fr-fr"}){
    dataRaw
    data {
      logo_coachello {
        alt
        url
      }
    }
  }
}

`
